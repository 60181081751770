@import '../sizes/sizes.scss';

.social-media-items-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: column;
  flex-wrap: wrap;
  gap: 25px 40px;

  > a {
    display: inline-flex;
    align-items: center;
    gap: $standard-padding;
    text-decoration: none;
    p {
      margin: 0;
    }
  }
}

.social-media-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px 0;
  @media only screen and (max-width: $mobile-size) {
    margin: 30px 0 50px 0;
  }
}
