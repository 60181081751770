// GOVUK styles with Great styles
// All classes are prefixed with either
// .govuk- or .great-
@import 'styles/great/main.scss';
@import '../widgets/forms.scss';
@import './forms.scss';
@import './social-media.scss';
@import '../fonts/main.scss';
@import './columns.scss';
@import '../microsite/columns.scss';
@import '../microsite/hero_video.scss';

.microsite {
  hr {
    border: none;
  }

  .block-text {
    i {
      font-size: inherit;
    }

    ul {
      padding-left: 20px;

      li {
        position: static;
        list-style: disc;
        padding: 0;
      }
    }

    hr {
      background: #666;
      height: 4px;
      width: 100%;
      margin: 30px 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
  .great-rtl {
    .govuk-grid-row {
      display: block;

      > div {
        float: right;
      }

      #related-content {
        float: left;
        position: relative;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
