@import '../sizes/sizes.scss';

.microsite {
  .microsite-column {
    .richtext-image {
      height: auto;
      width: -webkit-fill-available;
    }
    &.govuk-grid-column-one-quarter {
        @media only screen and (min-width: $mobile-size) {
          min-width: 200px;
        }
    }
  }
  .richtext {
    display: flex;
    flex-direction: column;

    img.richtext-image.right {
      margin-left: auto;
    }
  }
}
